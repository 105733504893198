import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

 const ViewAudio = () => {

    const location = useLocation();
	//const [viewAudioData, setViewAudioData] = useState(location.state); 
	const viewAudioData = location.state;
	
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
		navigate('/launch', { state: viewAudioData });
    } 

    const [checked, setChecked] = useState(false);
	
    const handleChange = (event) => {
        setChecked(event.target.checked);
        console.log(event.target.checked)
    }; 

    return (
        <Container component="main" maxWidth="md">
		<CssBaseline />
		<Box
			sx={{
				marginTop: 8,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				borderColor: 'primary.main',
                border: 1
			}}
		>

        <Typography component="h1" variant="h5">
            Audio Test
		</Typography>
		<Box component="form" noValidate sx={{ mt: 2 }}>
			<Typography component="h1" variant="h5">
			{viewAudioData.name}
			</Typography>
			
			<Typography component="body" variant="body">
				
			</Typography> 

			<FormControlLabel style={{textAlign: 'justify' , padding :3  }}
				control={<Checkbox checked={checked} onChange={handleChange} />}
				
				label="By clicking this box You agree to the following.  
				       The ACT Operations Portal (the “Site”) contains copyrighted material and is protected by copyright law. 
					   Without limiting the foregoing, You understand and acknowledge that certain content is copyrighted by ACT, Inc., including, but not limited to, ACT, Inc. test materials 
					   You may be able to view using the Site. ACT, Inc.'s secure tests and test questions may not be copied or disclosed at any time. 
					   You agree to keep such test materials secure and confidential in accordance with ACT, Inc. policies and procedures.  
					   Use of this  Site must also be in accordance with the policies and procedures provided in your administration instructions, and test accommodations must only be provided to specifically those examinees designated for the use of such materials, as approved by ACT, Inc."
			/>
			
		</Box>
		<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
		<Button
            type="submit"
            //fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={!checked}
		>
		Launch
		</Button>
		</Box>
		</Box>
		</Container>
    )
}
export default ViewAudio;