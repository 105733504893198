import React from 'react'; 
import { useLocation } from "react-router-dom";

export default function ViewAudioContent() {
	const location = useLocation();
	const viewAudioData = location.state; 
	
	const container = {
		position: 'relative',
		width: '100%',
		overflow: 'hidden',
		paddingTop: '56.25%'
    };
	
	const iframeContent = {
      position: 'absolute',
	  top: '0',
	  left: '0',
	  bottom: '0',
	  right: '0',
	  width: '100%',
	  height: '100%',
	  border: 'none'
    };

    return (
		<div style={container}>
			<iframe style={iframeContent} title='testContent' src={viewAudioData.url} ></iframe>
		</div>
    );
}