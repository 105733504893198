import React, { useState, useEffect } from 'react';
import Loading from './Loading';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import axios from "axios";
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function Login() {

  let emailHostName = `${window.Config.api}/audonl_1.0.0/emailservice`;  
  let userHostname = `${window.Config.api}/audonl_1.0.0/user`;  
  let userassignmentHostname = `${window.Config.api}/audonl_1.0.0/userassignment`;
   
  const [loginsuccess, setLoginsuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loginDetails, setLoginDetails] = useState({ loginEmail: '', loginPassword: '' });
  const [resetEmail, setResetEmail] = useState('');
  const [openResendAccessCode, setOpenResendAccessCode] = useState(false);
  
 useEffect(() => {
    async function chkValidUser() {
      try {
        if (!loginsuccess && loginDetails.loginEmail && loginDetails.loginPassword) {
          const response = await axios.get(`${userHostname}/login?email=${loginDetails.loginEmail}&access_code=${loginDetails.loginPassword}`);
          
		  if (response && response.data && response.status === 200) {
			//console.log("Login Success" );
            setLoginsuccess(true);
            setErrorMsg('');
            setIsError(false);
            getListAudio(response.data.jwtToken);
          }
          else {
            setLoginDetails(prev => ({
              ...prev,
              loginEmail: '',
              loginPassword: ''
            }));
            setIsLoading(false);
            setErrorMsg('Invalid Email or Access Code .');
            setIsError(true);
          }
        }
      } catch (error) {
        console.log(error);
        setLoginDetails(prev => ({
          ...prev,
          loginEmail: '',
          loginPassword: ''
        }));
        setLoginsuccess(false);
        setIsLoading(false);
        setErrorMsg('Invalid Email or Access Code.');
        setIsError(true);
      }
    }

    chkValidUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginDetails, isError, userHostname]);

  function NoRowsOverlay() {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        No Assignment found
      </Stack>
    );
  };

  function AccessCodeTitle(props) {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  const handleAccessCodeOpen = () => {
    setOpenResendAccessCode(true);
  };

  const handleAccessCodeClose = () => {
    setOpenResendAccessCode(false);
  };

  const handleRemailInputChange = (event) => {
    setResetEmail(event.target.value);
  };
//   async function getJWTToken(userEmail, userAccessCode){
    
//     const response = await axios.get(`${userHostname}/login?email=${userEmail}&access_code=${userAccessCode}`);
//           if (response && response.data && response.status === 200) {
//             console.log("Response Data :: " + JSON.stringify(response.data, 0, 2));
//             await setJWTToken(response.data.jwtToken);
//           }
// }

  const handleSendAccessCode = async () => {
    await sendEmail("FORGOTACCESSCODE", resetEmail );
    handleAccessCodeClose();
  }

  //Email Service to Send Email
  const sendEmail = async (operation, resetEmail) => {
    console.log("Sending Email ::::");
    let emailData = {
      "to": resetEmail,
      "cc": "",
      "subject": "",
      "body": "",
      "operation": operation,
      "accessCode": "",
      "assignmentList":""
    };
    
    await axios.post(emailHostName, emailData  ).then(res => {
      console.log(res.data);
    }).catch(function (error) {
      console.log(error);
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let email = data.get("email").trim();
    let password = data.get("password").trim();
    //console.log({email: email,password: password});

    if (email && password) {
      setLoginDetails(prev => ({
        ...prev,
        loginEmail: email,
        loginPassword: password
      }));
      setIsLoading(true);
    }
    else {
      setErrorMsg('Username or Access Code is empty');
      setIsError(true);
    }
  };

  
  async function getListAudio(token) {
    //console.log('Inside getListAudio');
	setIsLoading(true);

	let config = {
  	headers: {
      Authorization:token
	  }
	}

    const response = await axios.get(`${userassignmentHostname}?email=${loginDetails.loginEmail}`,config);

    if (response.data && response.status === 200 && response.data.responseBody) {
		//Passing only start_date with past and current date.
		setRowData(response.data.responseBody)
		//setRowData(response.data.responseBody.filter((assignment) => 
        //    Date.parse(assignment.start_date) <=  Date.parse(moment().format("YYYY/MMM/DD"))));
		setErrorMsg('');
		setIsError(false);
	    setIsLoading(false);
    } else {
      setErrorMsg('ASSIGNMENT not found');
      setIsError(true);
	  setIsLoading(false);
     }
     setIsLoading(false);
  };
  
  const navigate = useNavigate();
  const listAudioColumns = [
    {
      field: 'audio_file', headerName: 'Audio Assessment', width:450, 
      renderCell: (cellValues) => {
        return <Link component="button" href={`#${cellValues.row.url}`} 
                onClick={() => {
                    navigate('/prelaunch', { state: { name: cellValues.row.test_name , url: cellValues.row.url } });
          }}        
          >
          {cellValues.row.test_name}</Link >;
      },},
    { field: 'start_date', headerName: 'Start Date', width: 200 },
    { field: 'end_date', headerName: 'End Date', width: 200 }, ];

  const handleSnackbarClose = () => {
    setErrorMsg('');
    setIsError(false);
  };

  return (
    <Container component="main" maxWidth={(!loginsuccess) ? 'xs' : 'md'} >
      <CssBaseline />
      <Loading showLoading={isLoading} />

      {(isError) ? <Snackbar
        autoHideDuration={7000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={isError}
        onClose={handleSnackbarClose} >
        <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
          {errorMsg}
        </Alert>
      </Snackbar> : <></>}

      {
        (!loginsuccess) ? (

          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              margin: 'auto'
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
              <LockOutlinedIcon />
            </Avatar>

            <Typography component="h1" variant="h5"  >
              Sign In
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Username"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Access Code"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                SIGN IN
              </Button>
              <Grid container>
                <Grid item xs>
                  <Button onClick={handleAccessCodeOpen} variant="text" size="small">
                    Forgot Access Code ?
                  </Button>
                </Grid>
                <Dialog
                  open={openResendAccessCode}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  maxWidth="sm"
                >
                  <AccessCodeTitle id="customized-dialog-title" onClose={handleAccessCodeClose}>
                    Access Code
                  </AccessCodeTitle>
                  <DialogContent>
                    <Stack spacing={2}>
                      <TextField
                        id="resetEmail"
                        label="Username"
                        type={"email"}
                        required
                        variant="outlined"
                        onChange={handleRemailInputChange}
                      />
                    </Stack>
                  </DialogContent>
                  <DialogActions>
                    <Button autoFocus variant="contained" onClick={() => handleSendAccessCode()}>
                      Send Access Code
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Box>
          </Box>
        ) : (

          <Box sx={{ height: (theme) => theme.spacing(10) }} >
            <Typography component="h1" variant="h4" sx={{ m: 0, p: 2 }}>
              Select Audio Content
            </Typography>
            <Box sx={{
              paddingTop: '1%',
              margin: 'auto',
              width: '100%',
              height: '60vh',
              border: '1'
            }} >
              <DataGrid
                enablePagination="true"
                getRowId={(r) => r.test_id}
                rows={rowData}
                columns={listAudioColumns}
                sortingMode="server"
                autoHeight={false}
                hideFooter={false}
                components={{ NoRowsOverlay }}
				loading={isLoading}
				autoPageSize={true}
              />
            </Box>
          </Box >
        )
      }
    </Container >
  );
}