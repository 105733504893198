import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface LoadingProp {
  showLoading: boolean;
  title?: string;
}

export default function Loading(loadingProp: LoadingProp) {
  const { showLoading, title } = loadingProp;
  return (
    <Backdrop
      sx={{
        position: 'absolute',
        bgcolor: '#00000040',
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      open={showLoading}
    >
      <LinearProgress sx={{ position: 'absolute', width: 1, top: 0 }} />
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
        <CircularProgress size={72} />
        <Typography variant="h6" sx={{ fontSize: 24 }}>
          {title ?? 'LOADING'}
        </Typography>
      </Stack>
    </Backdrop>
  );
}