import './App.css';
import { CssBaseline } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@actinc/dls/components';
import Login from './components/common/Login';
import ViewAudio from './components/common/ViewAudio';
import ViewAudioContent from './components/common/ViewAudioContent';
import Header from './components/common/Header';


function App() {
	return (
		<div className="App">
			<ThemeProvider theme="ACT" >
				<CssBaseline>
					< Header />
					<BrowserRouter>
						<Routes>
							<Route path="/" element={<Login />} />
							<Route path="/login" element={<Login />} />
							<Route path="/prelaunch" element={<ViewAudio />} />
							<Route path="/launch" element={<ViewAudioContent />} />
						</Routes>
					</BrowserRouter>
				</CssBaseline>
			</ThemeProvider >
		</div>
	);
}
export default App;
